<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="400px"
    @ok="handleOk" @cancel="handleCancel">
        <!-- <div class="remind">
            <a-icon type="bell" />
            <span> 新增可见范围设置，管理员可根据不同部门选择展示不同话术，部门管理员可创建部门可见的话术</span>
        </div> -->
        <a-form :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index"  :label="item.label">
                <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio :value="0">全部员工</a-radio>
                    <a-radio :value="1">部门可见</a-radio>
                </a-radio-group >
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'group_name',
        label: '分组名称',
        type: 'input',
        rules: [{ required: true, message: '请输入分组名称!' }]
    },
    // {
    //     name: 'gender',
    //     label: '可见范围',
    //     type: 'radio',
    //     rules: [{ required: true, message: '请选择可见范围!' }],
    // },
]
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
                modalTitle:'添加分组'
            }
        },
        props: {
            item: Object
        },
        async created () {
            this.visible = true
            if(this.item){
                this.modalTitle = '编辑分组'
                await this.$nextTick()
                this.form.setFieldsValue({group_name:this.item.group_name})
            }
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                this.confirmLoading = true
                if(this.item){
                    params.group_id = this.item.group_id
                }
                await this.$store.dispatch(this.item?'discourseUPdateGroupAction':'discourseAddGroupAction', params)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            }
        },
    }
</script>

<style lang="scss" scoped>
    .remind{
        padding: 5px;
        background: #effaff;
        margin-bottom: 10px;
    }
</style>