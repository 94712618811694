<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="30.75%"
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index"  :label="item.label">
                <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-select v-else-if="item.type === 'select'" placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="回复内容">
                <div style="margin-bottom:10px">
                    <a-radio-group :value="radioVal" @change="handleChange">
                        <a-radio value="text">文字</a-radio>
                        <a-radio value="image">图片</a-radio>
                        <a-radio value="video">视频</a-radio>
                        <a-radio value="file">文件</a-radio>
                    </a-radio-group>
                </div>
                <div v-decorator="['discourse_content', { rules: [{ required: true, message: '请输入问答内容!' }] }]">
                    <div v-if="radioVal == 'text'">
                        <a-textarea @change="handleText" v-model="content" placeholder="请输入问答内容" :rows="4" />
                    </div>
                    <div class="talkingSkills-videoBox" v-if="radioVal == 'image'">
                        <!-- <span>图片名称: </span>
                        <a-input placeholder="图片名称可用于搜索" style="width:70%" /> -->
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <div style="margin-top:10px">
                            <a-upload
                                accept='.png,.jpeg,.jpg'
                                list-type="picture-card"
                                :file-list="imgList"
                                :data="uploadParams"
                                :action="uploadUrl"
                                :multiple='false'
                                :beforeUpload="beforeUploadImg"
                                @change="changeImg">
                                <div>
                                    <a-icon type="plus" />
                                </div>
                            </a-upload>
                        </div>
                    </div>
                    <div class="talkingSkills-videoBox" v-if="radioVal == 'video'">
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <div class="talkingSkills-videoImg" @click="toVideo" v-if="videoImg">
                            <a-icon class="talkingSkills-videoImg-play" type="play-circle" theme="twoTone" />
                            <a-icon @click.stop="delVideo" class="talkingSkills-videoImg-close" type="close-circle" theme="twoTone" two-tone-color="#999" />
                            <img :src="this.videoImg">
                        </div>
                        <div>
                            <a-upload
                                accept='.avi,.rmvb,.rm,.asf,.divx,.mpg,.mpeg,.mpe,.wmv,.mp4,.mkv,.vob'
                                :data="uploadParams"
                                :action="uploadUrl"
                                :multiple='false'
                                :show-upload-list="false"
                                :beforeUpload="beforeUploadVideo"
                                @change="changeVideo">
                                <div style="margin-top:10px" v-if="videoImg">
                                    <a-button> <a-icon type="upload" />重新上传</a-button>
                                </div>
                                <div v-else class="talkingSkills-video">
                                    <a-icon :style="{fontSize:'32px',color:'#999'}" type="plus" />
                                </div>
                            </a-upload>
                        </div>
                    </div>
                    <div class="talkingSkills-videoBox" v-if="radioVal == 'file'">
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <div v-if="fileInfo" class="talkingSkills-file">
                            <a-icon type="file" theme="twoTone" two-tone-color="#00cca2" :style="{fontSize:'36px'}" />
                            <a-icon @click.stop="delFile" class="talkingSkills-file-close" type="close-circle" theme="twoTone" two-tone-color="#999" />
                            <div style="margin-left:10px">
                                <div class="talkingSkills-file-name">{{fileInfo.name || fileInfo.ext_name}}</div>
                                <div v-if="fileInfo.size" class="talkingSkills-file-size">{{RenderSize(fileInfo.size)}}</div>
                            </div>
                        </div>
                        <div>
                            <a-upload
                                :data="uploadParams"
                                :action="uploadUrl"
                                :multiple='false'
                                :show-upload-list="false"
                                :beforeUpload="beforeUploadVideo"
                                @change="changeFile">
                                <div style="margin-top:10px" v-if="fileInfo">
                                    <a-button> <a-icon type="upload" />重新上传</a-button>
                                </div>
                                <div v-else class="talkingSkills-video">
                                    <a-icon :style="{fontSize:'32px',color:'#999'}" type="plus" />
                                </div>
                            </a-upload>
                        </div>
                    </div>
                </div>
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'group_id',
        label: '问答分组',
        type: 'select',
        rules: [{ required: true, message: '请选择问答分组' }],
        items: {
        data: 'group_list',
        label: 'group_name',
        value: 'group_id'
        }
    },
    {
        name: 'discourse_name',
        label: '问答标题',
        type: 'input',
        rules: [{ required: true, message: '请输入问答标题!' }]
    },
]
    import url from '@/utils/URL'
    import { RenderSize } from '@/utils/timeFormat'
    import createFilePath from '@/utils/tools'
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                loading: false,
                confirmLoading: false,
                modalTitle:'新建问答',
                form: this.$form.createForm(this),
                seleteItems: {
                    group_list:[],
                },
                imgList:[],
                uploadParams: {},
                uploadUrl: url.uploadQiNiu,
                videoImg:'',
                videoSrc:'',
                fileInfo:'',
                radioVal:'text',
                content:'',
                ext_name:''
            }
        },
        props:{
            groupID:Number,
            item:Object
        },
        async created () {
            this.visible = true
            this.getGroup()
            if(this.item){
                this.modalTitle = '编辑问答'
                await this.$nextTick()
                this.radioVal = this.item.discourse_type
                this.content = this.item.discourse_content
                this.ext_name = this.item.ext_name
                if(this.item.discourse_type == 'image'){
                    let signIndex = this.item.discourse_content.search(/cn/)
                    let sign = this.item.discourse_content.substring(signIndex + 3)
                    let obj = {
                        uid: `-1`,
                        name: `${this.item.ext_name}`,
                        status: 'done',
                        url:this.item.discourse_content,
                        type:'image/jpeg',
                        response:{
                            key:sign
                        }
                    }
                    this.imgList = [obj]
                }else if(this.item.discourse_type == 'video'){
                    this.videoSrc = this.item.discourse_content
                    this.videoImg = `${this.item.discourse_content}?vframe/jpg/offset/8/h/104`

                }else if(this.item.discourse_type == 'file'){
                    this.fileInfo = this.item
                }else{}
                this.form.setFieldsValue({discourse_name:this.item.discourse_name,discourse_content:this.item.discourse_content})
            }
        },
        methods: {
            RenderSize,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getGroup() {
                let res = await this.$store.dispatch('discourseGroupAction', {})
                this.seleteItems.group_list = res.data
                this.form.setFieldsValue({group_id:this.groupID})
            },
            async handleOk(){
                let params = await this.form.validateFields()
                params.discourse_type = this.radioVal
                params.discourse_content = this.content
                params.ext_name = this.ext_name
                if(this.item){
                    params.discourse_id = this.item.id
                }
                this.confirmLoading = true
                await this.$store.dispatch(this.item?'discourseUpdateAction':'discourseAddAction', params)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
                
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            async beforeUploadImg(file,fileList) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            async changeImg({file}){
                this.loading = true
                this.confirmLoading = true
                if(file.status == 'done'){
                    this.loading = false
                    this.confirmLoading = false
                    this.content = file.response.key
                    this.ext_name = file.name
                }
                if(file.status == 'removed'){
                    this.loading = false
                    this.confirmLoading = false
                    this.imgList = []
                    this.ext_name = ''
                    this.content = ''
                    this.form.setFieldsValue({discourse_content:''})
                }else{
                    this.loading = false
                    this.imgList = [file]
                }
            },
            async beforeUploadVideo(file,fileList) {
                if (!file) { return false }
                let res = await this.$store.dispatch('massTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    let obj = {
                        uid:file.uid,
                        url:this.uploadParams.key,
                    }
                    return true
                }
                return false
            },
            changeVideo({file}){
                this.loading = true
                this.confirmLoading = true
                if(file.status == 'done'){
                    this.loading = false
                    this.confirmLoading = false
                    this.videoImg = `http://v01.wedots.cn/${file.response.key}?vframe/jpg/offset/8/h/104`
                    this.videoSrc = `http://v01.wedots.cn/${file.response.key}`
                    this.content = file.response.key
                    this.ext_name = file.name
                }
            },
            changeFile({file}){
                this.loading = true
                this.confirmLoading = true
                if(file.status == 'done'){
                    this.loading = false
                    this.confirmLoading = false
                    this.fileInfo = file
                    this.content = file.response.key
                    this.ext_name = file.name
                }
            },
            toVideo(){
                window.open(this.videoSrc)
            },
            delVideo(){
                this.videoSrc = ''
                this.videoImg = ''
                this.uploadParams = {}
                this.form.setFieldsValue({discourse_content:''})
            },
            delFile(){
                this.fileInfo = ''
                this.uploadParams = {}
                this.form.setFieldsValue({discourse_content:''})
            },
            handleChange(e){
                this.radioVal = e.target.value
                this.content = ''
                this.ext_name = ''
                this.videoImg = ''
                this.videoSrc = ''
                this.fileInfo = ''
                this.imgList = []
                this.imgAddr = []
                this.form.setFieldsValue({discourse_content:''})
            },
            handleText(){
                this.form.setFieldsValue({discourse_content:this.textVal})
            }
        },
    }
</script>

<style lang="scss" scoped>
    .talkingSkills-videoBox{
        background: #fbfbfb;
        border-radius: 2px;
        border: 1px solid #eee;
        padding: 12px 16px;
    }
    .talkingSkills-videoImg{
        position: relative;
        display: inline-block;
        &-play{
            position: absolute;
            left: 50%;
            margin-left: -12px;
            top:40px;
            font-size: 24px;
        }
        &-close{
            position: absolute;
            right: -8px;
            top: -8px;
            font-size: 20px;
        }
    }
    .talkingSkills-video{
        display: table;
        float: left;
        width: 104px;
        height: 104px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        -webkit-transition: border-color 0.3s ease;
        transition: border-color 0.3s ease;
        line-height: 104px;
    }
    .talkingSkills-video:hover{
        border-color: #00cca2;
    }
    .talkingSkills-file{
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #eee;
        border-radius: 2px;
        padding: 10px;
        width: 250px;
        position: relative;
        &-name{
            width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
        }
        &-size{
            font-size: 12px;
            color: #909090;
        }
        &-close{
            position: absolute;
            right: -8px;
            top: -8px;
            font-size: 20px;
        }
    }
</style>