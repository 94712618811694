<template>
  <div>
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
            <a-form-item v-if="canSave">
                <a-button @click='showEditModal()' type="primary" icon="plus" >新增Q&A</a-button>
            </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
              <a-input v-model='searchParams.search.discourse_name'  style="width: 160px" allowClear placeholder="问答标题"></a-input>
          </a-form-item>
          <a-form-item>
              <a-tooltip placement="topLeft" >
              <a-select v-model='searchParams.search.discourse_type' style="width: 160px" placeholder="回复类型">
                  <a-select-option value="">全部类型</a-select-option>
                  <a-select-option value="text">文本</a-select-option>
                  <a-select-option value="image">图片</a-select-option>
                  <a-select-option value="video">视频</a-select-option>
                  <a-select-option value="file">文件</a-select-option>
              </a-select>
              </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-button @click="searchList" html-type="submit" :loading="loading" type="primary" >搜索</a-button>
          </a-form-item>
      </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table :pagination="false" :bordered='false' rowKey="id" size="small"
        :columns="columns" :dataSource="list" @change="handleChange">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="content" slot-scope="text, record , index">
          <div class="talkingSkills-list-content">
            <div v-if="record.discourse_type == 'text'">{{text}}</div>
            <div class="talkingSkills-list-content-img" v-if="record.discourse_type == 'image'">
              <img :preview='index' :src="text" alt="">
              <div>{{record.ext_name}}</div>
            </div>
            <div class="talkingSkills-list-content-video" v-if="record.discourse_type == 'video'">
              <a :href="text" target="_blank">
                <a-icon class="talkingSkills-list-content-video-icon" type="play-circle" theme="twoTone" />
                <img :src="`${text}?vframe/jpg/offset/8/h/60`" alt="">
              </a>
              <div>{{record.ext_name}}</div>
            </div>
            <div v-if="record.discourse_type == 'file'" class="talkingSkills-list-content-img" @click="toExport(record)" style="cursor:pointer">
              <a-icon type="file" two-tone-color="#00cca2" theme="twoTone" :style="{fontSize:'36px'}" />
              <div>{{record.ext_name}}</div>
            </div>
          </div>
        </template>
        <template slot="action" slot-scope="text,record">
          <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
          <a-divider v-if="canUpdate" type="vertical" />
          <a v-if="canDelete" href="javascript:;" @click="deleteItem(record)">删除</a>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
      <editModal v-if="isEditModal" :groupID='groupID' :item='modalData' />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '问答标题', dataIndex: 'discourse_name', key: '9',width:'200px',ellipsis:true},
  { title: '回复内容', dataIndex: 'discourse_content', key: '1',scopedSlots: { customRender: 'content' }},
  { title: '使用次数', dataIndex: 'used_count', key: 'used_count',align:'center',width:'100px',sorter:()=>{}},
  { title: '内容类型', dataIndex: 'discourse_type_name',ellipsis:true,align:'center',width:'100px'},
  { title: '创建人', dataIndex: 'created_by',ellipsis:true,align:'center',width:'100px'},
  { title: '创建时间', dataIndex: 'created_at',ellipsis:true,align:'right',width:'200px',sorter:()=>{}},
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' },width:'100px'}
]

    import editModal from './editModal'
    import tableMixins from '@/common/mixins/table'
    import authority from '@/common/mixins/authority'
    export default {
        name:"list",
        inject: ['parent'],
        data() {
            return {
                columns,
                loading:false,
                list: [],
                modalData:{},
                isEditModal:false,
                authType:['crm','discourse'],
            }
        },
        components: {
            editModal,
        },
        props:{
            groupID:Number
        },
        mixins: [ tableMixins, authority ],
        methods: {
            async getList() {
                if(this.groupID === 0) return false
                this.loading = true
                this.searchParams.search.group_id = this.groupID
                let res = await this.$store.dispatch('discourseIndexAction', this.searchParams)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            handleChange(pagination, filters, sorter){
              if(sorter.order){
                  if(sorter.order === "ascend"){
                      this.searchParams['sort'] = `${sorter.field}`
                  }else{
                      this.searchParams['sort'] = `-${sorter.field}`
                  }
                  }else{
                  this.searchParams['sort'] = ''
                  }
              this.getList()
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                    this.parent.refreshList()
                }
                this.isEditModal = false
            },
            deleteItem(item){
              let that = this
              this.$confirm({
                  title: `您确定删除该问答？`,
                  okText: '确定',
                  okType: 'danger',
                  cancelText: '取消',
                  onOk() {
                  return new Promise(async (resolve, reject) => {
                      await that.$store.dispatch('discourseDeleteAction', {discourse_id:item.id})
                      .then(res=>{
                          that.$message.success('操作成功~')
                          that.getList()
                          that.parent.refreshList()
                          resolve(res)
                      })
                      .catch(err=>{
                          that.$message.error('操作失败~')
                          reject(err)
                      })
                  }).catch(error => console.log(error))
                  }
              })
            },
            toExport(item){
              window.open(item.discourse_content)
            }
        },
        watch: {
            groupID(newValue, oldValue) {
                this.searchParams.page = 1
                this.getList()
            }
        },
    }
</script>

<style lang="scss" scoped>
  .talkingSkills-list-content{
    &-img{
      display: flex;
      align-items: center;
      min-height: 60px;
      img{
        max-height: 60px;
        max-width: 60px;
      }
      div{
        margin-left: 10px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
    &-video{
      display: flex;
      align-items: center;
      max-height: 60px;
      min-height: 30px;
      img{
        max-height: 60px;
        max-width: 60px;
      }
      div{
        margin-left: 10px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
      a{
        position: relative;
        .talkingSkills-list-content-video-icon{
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -6px;
          margin-left: -6px;
        }

      }
    }
  }
</style>